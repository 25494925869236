import React, { FC } from "react"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTag } from "@fortawesome/free-solid-svg-icons"

type Props = {
  title: string
  permalink: string
  date: string
  tags: Array<string>
}

const ArticleHeader: FC<Props> = ({ title, permalink, date, tags }) => (
  <header>
    <h1>
      <Link to={permalink}>{title}</Link>
    </h1>
    <strong>
      <time dateTime={date}>{date}</time>
      {tags && (
        <small>
          {" "}
          {tags.map((tag) => (
            <Link to={`/tags/#${tag}`} key={tag} style={{ marginRight: "0.5em" }}>
              <FontAwesomeIcon icon={faTag} style={{ marginRight: "0.1em" }} />
              {tag}
            </Link>
          ))}
        </small>
      )}
    </strong>
  </header>
)

export default ArticleHeader
