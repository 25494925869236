import React, { FC } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/layout"
import ArticleHeader from "../components/article-header"

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date
        tags
      }
    }
  }
`

type QueryProps = {
  data: {
    markdownRemark: {
      html: string
      fields: {
        slug: string
      }
      frontmatter: {
        title: string
        date: string
        tags: Array<string>
      }
    }
  }
}

type Props = PageProps & QueryProps

const BlogPost: FC<Props> = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <article>
        <ArticleHeader
          title={post.frontmatter.title}
          permalink={post.fields.slug}
          date={post.frontmatter.date}
          tags={post.frontmatter.tags}
        />
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </article>
    </Layout>
  )
}

export default BlogPost
